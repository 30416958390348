import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SettingDto, SystemCodeDto } from '../dto/dto';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor() {}
  private _loading = new BehaviorSubject<boolean>(false);
  loading = this._loading.asObservable();
  public displayLoading(request: boolean = true) {
    setTimeout(() => {
      this._loading.next(request);
    });
  }

  private _systemCodes = new BehaviorSubject<SystemCodeDto[]>([]);
  systemCodes = this._systemCodes.asObservable();
  public setSystemCodes(request: SystemCodeDto[]) {
    this._systemCodes.next(request);
  }
  private _setting = new BehaviorSubject<SettingDto>(null);
  setting = this._setting.asObservable();
  public setSetting(request: SettingDto) {
    this._setting.next(request);
  }

  public getSystemCodeId(entity: string, value: string): string {
    const result = this._systemCodes.value.find(
      (item) => item.entity === entity && item.value === value
    );

    return result?.id || '';
  }
  public getSetting(): SettingDto {
    return this._setting.value;
  }
}
